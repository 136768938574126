import React from "react";
import { SocialLinksItemsType } from "components/LSRevamp/LSRevampInterfaces";
import withWindowDimensions from "components/common/withWindowDimensions";
import Image from "next/image";

const SocialLinksCard = ({
  logo = { path: "", width: null, height: null },
  count,
  countType,
  secondLogo = { path: "", width: null, height: null },
  backgroundColorClass,
  isMobileSized,
}: SocialLinksItemsType) => {
  return (
    <div
      className={`${backgroundColorClass} w-40 md:w-240 h-[144px] rounded-md py-4 pl-4 relative`}
    >
      {logo?.path?.length > 0 && (
        <Image
          src={logo.path}
          alt="communityLogo"
          width={logo.width}
          height={logo.height}
        />
      )}

      {secondLogo?.path?.length > 0 && (
        <div
          className={`absolute ${
            countType === "Community Members" && isMobileSized
              ? "top-10 left-20"
              : "top-20 right-20"
          } w-2/6 md:w-auto`}
        >
          <Image
            src={secondLogo.path}
            width={secondLogo.width}
            height={secondLogo.height}
            alt="communityLogo"
          />
        </div>
      )}
      <div className="mt-2 absolute bottom-20">
        <p className="font-bold text-2xl md:text-3xl">
          {count}
          <span
            className={`absolute ${
              countType === "Community Members" && isMobileSized
                ? "bottom-[34px]"
                : "bottom-[19px]"
            } `}
          >
            +
          </span>
        </p>
        <p className="text-base md:text-xl font-extralight leading-4">{countType}</p>
      </div>
    </div>
  );
};

export default withWindowDimensions(SocialLinksCard);
