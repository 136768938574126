import { SocialLinksItemsType } from "components/LSRevamp/LSRevampInterfaces"
import SocialLinksCard from "components/LSRevamp/common/SocialLinksCard"
import { socialLinksItems } from "components/LSRevamp/constant"

const SocialLinksSection = () => {
  return (
    <div className="md:max-w-[1200px] md:px-4 md:m-auto mb-[70px] md:pb-[80px] md:pt-4">
      <h2 className="font-bold text-[24px] md:text-[36px] mt-8 md:mt-0 text-center px-8 md:px-0 mb-8">
        Join India's largest communities
      </h2>
      <div className="m-auto flex flex-wrap gap-6 justify-center md:nowrapjustify-evenly">
        {socialLinksItems.map((item: SocialLinksItemsType, index) => {
          return (
            <SocialLinksCard
              key={index}
              logo={item.logo}
              secondLogo={item.secondLogo}
              count={item.count}
              countType={item.countType}
              backgroundColorClass={item.backgroundColorClass}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SocialLinksSection
